import { Addon} from '@antv/x6';
import { onRandomPlatform } from '@/utils/tools'
import { NODE_NAME } from "@/service/workflow-service";
// 拖拽生成四边形
export const startDragToGraph  = (graph,type,e,code) =>{
  const random = onRandomPlatform()
  const nodeStart = `
          <div class="node-box" style="background-color:#2d8cf0">
              <span class="node-btn-start" style="background-color:#2d8cf0"></span>
          </div>`
  const nodeEnd = `
          <div class="node-box" style="background-color:#e73f46">
              <span class="node-btn-end" style="background-color:#e73f46"></span>
          </div>`
  const nodeAction = `
          <div class="node-box">
              <span class="${type.icon}" style="background-color:#fcce51"></span>
              <span class="node-text"></span>
              <font class="layout-node-code">${random}</font>
          </div>`
  const nodeElement = `
          <div class="node-box">
              <span class="${type.icon}" style="background-color:#fda173"></span>
              <span class="node-text"></span>
              <font class="layout-node-code">${random}</font>
          </div>`
  let htmlComponent
  switch (code){
    case 'event':
      if(type.nodeCode === NODE_NAME.NODE_START){
        htmlComponent = nodeStart
      }
      if(type.nodeCode === NODE_NAME.NODE_END){
        htmlComponent = nodeEnd
      }
      break;
    case 'action':
      htmlComponent = nodeAction
      break;
    case 'baseElement':
      htmlComponent = nodeElement
      break;
  }
  //const warp = type.nodeCode!=NODE_NAME.NODE_START && type.nodeCode!=NODE_NAME.NODE_END?`<font class="layout-node-code">${random}</font>`:''
  const node = graph.createNode({
    width: type.width,  //节点的宽度
    height: 33,   //节点的高度
    nodeCode:type.nodeCode,
    nodeName:type.name,
    nodeNumber:random,
    attribute:{
      nodeTitle:'',
      nodeContent:'这里是作业内容，请根据内容提示进行作业',
      inputTypeName:'', //输入类型文字
      inputTypeCode:'', //输入类型
      unitType:'', //单位
      voiceAnnouncements:1, //语音播报
      customText:'', //自定义内容
      modelName:'', //模型名称
      deviceName:'', //设备名称
      backgroundText:'', //背景文字
      backgroundCode:'', //背景类型
      announcementsText:'', //播报文本
      announcementsType:'', //播报类型
      endSettingsText:'', //结束设置的文本
      endSettingsType:'', //结束设置的类型
      timedJumpStatus:0,  //定时跳转
      timedJumpNumber:5, //定时跳转设置时间
      timedRecordingStatus:0, //定时录音状态
      timedRecordingNumber:5, //定时录音设置时间
      cameraTimerStatus:0, //定时拍照状态
      cameraTimerNumber:5, //定时拍照设置时间
      nodeBtnList:[],
      imageMode:'center', //图片展示方式
      imageId:'', //图片id
      imageUrl:'https://arsources.ustep.cn/inspection/52451716347811_.pic.jpg',//图片url
      annexList:[], //附件列表数据
      batchList:[], //批量操作数据
      batchStatus:0, //批量操作/分组状态 0代表批量操作 1代表批量分组
      batchInputList:[], //批量输入数据
    },
    attrs: {
      label: {
        text: type.name,
        fill: type.color,
        // fontSize: 14,
        textWrap: {
          width: '60%' ,
          height: -10,
          ellipsis: true,
        },
        refX:'33%',
        textAnchor:'Start',
      },
      // body: {
      //   stroke: type.borderColor,
      //   strokeWidth: 2,
      //   fill: type.background,
      //   rx: 4, // 圆角矩形
      //   ry: 4,
      // },
    },
    shape: 'html',
    html: htmlComponent,
    ports: ports,
  })
  const dnd = new Addon.Dnd({target:graph})
  dnd.start(node,e)
}
const ports = {
  groups: {
    // // 输入链接桩群组定义
    // top: {
    //   name:'top',
    //   position:'top',
    //   markup: [
    //     {
    //       tagName: 'rect',
    //       selector: 'body',
    //     },
    //   ],
    //   attrs: {
    //     rect: {
    //       magnet: true,
    //       fill: 'rgba(0,0,0,0)',
    //       width: 130,
    //       height: 8,
    //       class:'group-hover',
    //       x:-65,
    //       y:0,
    //     },
    //   },
    //   zIndex:2001
    // },
    // // 输出链接桩群组定义
    // bottom: {
    //   name:'bottom',
    //   position:'bottom',
    //   markup: [
    //     {
    //       tagName: 'rect',
    //       selector: 'body',
    //     },
    //   ],
    //   attrs: {
    //     rect: {
    //       magnet: true,
    //       fill: 'rgba(0,0,0,0)',
    //       width: 130,
    //       height: 8,
    //       class:'group-hover group-bottom',
    //       x:-65,
    //       y:-8,
    //     },
    //   },
    //   zIndex:2001
    // },
    // left: {
    //   name:'left',
    //   position:'left',
    //   markup: [
    //     {
    //       tagName: 'rect',
    //       selector: 'body',
    //     },
    //   ],
    //   attrs: {
    //     rect: {
    //       magnet: true,
    //       fill: 'rgba(0,0,0,0)',
    //       width: 8,
    //       height: 33,
    //       class:'group-hover group-left',
    //       x:0,
    //       y:-16.5,
    //     },
    //   },
    //   zIndex:2001
    // },
    // right: {
    //   name:'right',
    //   position:'right',
    //   markup: [
    //     {
    //       tagName: 'rect',
    //       selector: 'body',
    //     },
    //   ],
    //   attrs: {
    //     rect: {
    //       magnet: true,
    //       fill: 'rgba(0,0,0,0)',
    //       width: 8,
    //       height: 33,
    //       class:'group-hover group-right',
    //       x:-8,
    //       y:-16.5,
    //     },
    //   },
    //   zIndex:2001
    // },
    // 输入链接桩群组定义
    top: {
      position: 'top',
      attrs: {
        circle: {
          r: 4,
          magnet: true,
          stroke: '#2D8CF0',
          strokeWidth: 2,
          fill: '#fff',
        },
      },
      zIndex:2000
    },
    // 输出链接桩群组定义
    bottom: {
      position: 'bottom',
      attrs: {
        circle: {
          r: 4,
          magnet: true,
          stroke: '#2D8CF0',
          strokeWidth: 2,
          fill: '#fff',
        },
      },
    },
    left: {
      position: 'left',
      attrs: {
        circle: {
          r: 4,
          magnet: true,
          stroke: '#2D8CF0',
          strokeWidth: 2,
          fill: '#fff',
        },
      },
    },
    right: {
      position: 'right',
      attrs: {
        circle: {
          r: 4,
          magnet: true,
          stroke: '#2D8CF0',
          strokeWidth: 2,
          fill: '#fff',
        },
      },
    },
  },
  items: [
    {
      id: 'port1',
      group: 'top',
    },
    {
      id: 'port2',
      group: 'bottom',
    },
    {
      id: 'port3',
      group: 'left',
    },
    {
      id: 'port4',
      group: 'right',
    }
  ],
}
